import Layout from "../Layout";
import "./checkout.css"
import {
    CourseBlock,
    HeaderBox, HeaderDescription, HeaderTitle, PageBlock, PrimaryText, SecondaryText
} from "./../../features/Atoms"
import { Grid, Box, Checkbox, FormControlLabel, Slider, Hidden, CircularProgress } from "@material-ui/core";
import coverImg from "./../../images/course_header.jpg";
import { useDispatch, useSelector } from 'react-redux'
import { loadAllMathsCourse } from "./../../DataStore/CourseReducer"
import { useEffect, useState } from "react";
import Data from "../../Data";
import course1 from "./../../images/course1.JPG"
import Api from "../../Api";
import myicon from "../../images/online-learning.png"
import deleteicon from "../../images/delete.png";
import axios from "axios";
import { Description, TextRotateUpOutlined } from "@material-ui/icons";
import { bookSchoolCourse } from "../../DataStore/SchoolCoursesReducer";

export default function CheckOut(props) {

    

    useEffect(() => {
        
        const mydata = localStorage.getItem('allEntries')
        console.log("this is my data ", data)
       

        if(mydata === null || mydata === `[]` ){
            setNoresult(true)
        }else{
            const ldata = JSON.parse([mydata])
            // setAuthdata(ldata)
            // const dodo = authdata.map((item)=> {
            //    return  item
            // })
            ldata.map((item) => {
                setNewdatame(item)
            } );
          
           

           

            setData(ldata)
           
            console.log(ldata.length)
            if(ldata.length === 1 ){
                
                const ids = ldata.map((item) => item.price );
                                    const  sum = ids.reduce((a, b) => a + b, 0);
                                    setSubtotal(sum)
                                    const newt = subtotal
                                    setTotal(sum)
            }else{
                const ldata = JSON.parse([mydata])
                                    const mylastname = ldata[0].lastName
                                    const lastnames = ldata[1].lastName

                                    if(mylastname === lastnames){
                                                                const ids = ldata.map((item) => item.price );
                                                                const  sum = ids.reduce((a, b) => a + b, 0);
                                                                const adddiscount = ids.splice(1)
                                                                const sumdiscount = adddiscount.reduce((a,b) => a + b, 0);
                                                                 const maindiscount = sumdiscount 
                                                                // setDiscount(maindiscount)
                                                              

                                                                // const percentage = maindiscount *5
                                                                 const medis = 0/100 * sumdiscount
                                                                 const newmedis = medis.toFixed(3)
                                                                 setDiscount(newmedis)
                                                                 setSubtotal(sum)
                                                                 const mt = sum - newmedis
                                                                 setTotal(mt)
                                                                


console.log("my discount", sumdiscount, sum)
                                                                 
                                                
                                                               
                                                               
                                                              
                                                               
                                                               
                                                
                                                                
                                                
                                                
                                                                 
                                                             }else{
                                                                
                                                                const ldata = JSON.parse([mydata])
                                                                setData(ldata)
                                                                const ids = ldata.map((item) => item.price );
                                                                const  sum = ids.reduce((a, b) => a + b, 0);
                                                                setSubtotal(sum)
                                                                const newt = subtotal
                                                                setTotal(sum)

                                                             }
                                      
                
            }



        }
        
     

    }, [])








    const [filterQuery, setFilterQuery] = useState({});
    const courseListStatus = useSelector(state => state.course.loadAllMathsCourse && state.course.loadAllMathsCourse)
    const dispatch = useDispatch();
    const [mounted, setMounted] = useState(false)
    const [data, setData] = useState([])
    const [subtotal, setSubtotal] = useState()
    const [discount, setDiscount] = useState()
    const [total, setTotal] = useState('')
    const [coupondata, setCoupondata] = useState(Data.Config.Coupon)

    const [cardName, setCardName] = useState('')
    const [cardNumber, setCardNumber] = useState('')
    const [exp, setExp] = useState('')
    const [realexp, setRealexp] = useState('')
    const [scode, setScode] = useState('')
    const [noresult, setNoresult] = useState()
    const [discountlastname, setDiscountlastname] = useState()
    const [ singlediscount, setSinglediscount ] = useState()
    const [id , setId] = useState('')
    const [coupon , setCoupon] = useState('')
    const [showcouponbtn, setShowcouponbtn] = useState(true)
    const [errors, setErrors] = useState()
    const [mytitle, setMytitle] = useState(data.title)
    const [authdata, setAuthdata] = useState([])
    const [newdatame, setNewdatame] = useState()
    const [loader, setLoader] = useState('make payment')
    const [disable, setDisable] = useState(false)



    // const a=exp
    //     const b=a.replace(/\,/g,'')
    //     setExp(b)


    


   console.log("text", newdatame)
      
  

    // const applyCoupon = ()=> {
    //    setCoupon(50)
       
    // }

    // const dodiscount = (item)=>{
    //     const newcouponamount = total - item.amount 
    //    setTotal(newcouponamount)
    // }

    const applyCoupon = (e) => {
        e.preventDefault();
         


        if(coupon === "abcd"){
            setTotal(total - 10)
            setShowcouponbtn(false)
        }else{
            alert("Coupon is invalid")
        }
      
      }
   

const removeitem = () => {
const index = data.findIndex(key => key.courseId === `${id}`);
console.log(index)
data.splice(index,1);
console.log(data);
localStorage.setItem("allEntries", JSON.stringify(data));
window.location.reload(false);

   
   }

    

  


    const makepayment = ()=> {
    
        
        let a=exp;
        var  b=a.replace(/[/]/g,''); // 1125, but a string, so convert it to number
        console.log(b)
          
      
    
       
       
        
       
       
        setLoader('processing payment...')
        const data = {
            cc:`${cardNumber}`,
            cvv:scode,
            expire:b,
            amount:`${total}`,
            cardName: cardName,
            description: newdatame.title,
            childName: newdatame.firstName,
            email:newdatame.parentEmail,

            
            firstName:newdatame.firstName,
            lastName: newdatame.lastName,
            userId:newdatame.userId,
            schoolId:newdatame.schoolId,
            courseId:newdatame.courseId,
            userId:newdatame.userId

        }
        console.log("this is working ", data)


        Api.Payment.create(data).then(function (res) {
            console.log("here", res.error)
              if(res.error){
             console.log(res.error)
              res.error.map((items)=> {
                  console.log(items.msg)
                  setErrors(items.msg)
                  setLoader('make payment')
                  setDisable(false)
              })
         }else{
             console.log(res.data)
             if(res.data === "Transaction was approved"){
                 console.log("Transaction was approved.")
                 setLoader('This transaction has been approved')

                 const data = {
                    "parentEmail":newdatame.parentEmail,
                    "firstName":newdatame.firstName,
                    "lastName": newdatame.lastName,
                    "userId":newdatame.userId,
                    "schoolId":newdatame.schoolId,
                    "courseId":newdatame.courseId,
                    "grade":newdatame.grade,
                    "addressLine1": newdatame.addressLine1,
                    "contactNo": newdatame.contactNo,
                    "studentAge": newdatame.studentAge
                  }

                  Api.Payment.bookslot(data).then(function (res){
                      //console.log(res.data)
                    if(res.meta.message === "Course booked Successfully"){
                                setLoader('make payment')
                                alert("Thank you! Course booked Successfully")
                                removeitem(setId(data.courseId))
                            }
                  })
                
        
             }else{
                 if(res.data === "Failed Transaction"){
                    console.log("This transaction was not approved.", res.data)
                    alert(`Your transaction was not Successful, ${res.meta.message}`)
                    setLoader('make payment')
                 }
                
            
             }
         }
        }) 


    
    }

    

    const calculatediscount = (item) => {
    //   const discountprice = item.price - discount
    //   setSinglediscount(discountprice)
  }

    useEffect(() => {
        if (!mounted) {
            dispatch(loadAllMathsCourse());
            setMounted(true)
            // alert("this is working")
        }
    }, [])

    const handleFilterChange = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        const newFilterQuery = { ...filterQuery }

        const queryFieldValues = filterQuery[fieldName] || []
        const newQueryFieldValues = e.target.checked
            ? [...queryFieldValues, fieldValue]
            : queryFieldValues.filter(item => item !== fieldValue);
        newFilterQuery[fieldName] = newQueryFieldValues;
        setFilterQuery({
            ...newFilterQuery
        })
        dispatch(loadAllMathsCourse(newFilterQuery));
    }

    return (
        <Layout className="detailsBlock" fillHeader style={{ background: '#f6f7f7', paddingTop: "7%" }}>


            
            <div className="container uk-margin-large-top" >
            {
                 noresult === true?( <div className="uk-height-medium uk-flex uk-flex-center uk-flex-middle">
                                      <h3>No items in your cart</h3>
                                     </div>
             
             ):(
             
             <div className="row uk-margin-large-bottom">
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">

                   
                    <h6>Order Summary</h6>
                        {
                            data.map(item => (
                                <div>
                                   
                                     <div class="uk-card uk-card-default uk-card-body uk-card-small uk-margin-small-top uk-margin-small-bottom  " style={{ borderRadius: 10 }}>
                                     
                            <div className="uk-grid" data-uk-grid>
                                <div className=" uk-width-1-5 " >
                                    <div class="uk-text-left">
                                        <img src={myicon} />
                                    </div>
                                </div>

                                <div className=" uk-width-1-2 uk-margin-small-top " >
                                    <div class=" uk-text-left ">
                                        <div>

                                            <p style={{fontSize:14}}>{item.title}</p>
                                        </div>

                                        <div>
                                          
                                         <p style={{fontSize:14}}>  Grade: {item.grade}</p>
                                        </div>

                                        <div>
                                            <p style={{fontSize:14}}>  Student name: {item.firstName} {item.lastName} </p>
                                        </div>

                                        <p style={{color:'red', fontSize:14}} onClick={()=> removeitem(
                                       setId(item.courseId)
                                    ) } class="uk-text-left"> 
                                     Delete item
                                    </p>


                                    </div>
                                </div>

                                <div className=" uk-text-center uk-margin-small-top " >
                                    <p>${item.price}
                                    {
                                        discountlastname === item.lastName?(
                                            calculatediscount(item)
                                        
                           
                                        ):(null)
                                    }
                                    
                                    
                                    </p>
                                </div>

                                <div className=" uk-width-1-3  uk-margin-small-top " >

                                   
                                </div>
                            </div>
                        </div>
                                 </div>
                                
                               
                            )

                            )
                        }
                    


                    </div>


                    <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                    {/* <div> <h3 className="uk-text-left">Make payment</h3> </div> */}
                    <div>
    <div class="uk-grid-small" data-uk-grid>
           <p style={{color:'red', fontSize:14}}> {errors}</p>
        <div class="uk-width-1-1">
            <p className="fieldTitle">Card Number</p>
            <input
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)} className="uk-input customInput fieldTitle" type="text" placeholder="Card number" />
        </div>
        <div class="uk-width-1-1">
            <p className="fieldTitle"> Card Name</p>
            <input
                value={cardName}
                onChange={(e) => setCardName(e.target.value)} className=" fieldTitle uk-input customInput" type="text" placeholder="Card name" />
        </div>





        <div class="uk-width-1-2@s">
            <p className="fieldTitle">Expiration Date</p>
            <input
                value={exp}
                onChange={(e) => setExp(e.target.value)} className=" fieldTitle uk-input customInput" type="text" placeholder="05/22" />
        </div>
        <div class="uk-width-1-2@s">
            <p className="fieldTitle">Security Number</p>
            <input
                value={scode}
                onChange={(e) => setScode(e.target.value)} className="fieldTitle uk-input customInput" type="password" placeholder="CVV" />
        </div>

        <div className="uk-width-1-1@s uk-margin-top">
        <button   onClick={makepayment} style={{ fontSize: '14px', backgroundColor:'green', color:'white', borderRadius:5 }} class="uk-button  uk-width-1-1">{loader}</button>
        
</div>
    </div>
</div>
</div>


</div>
                        
                     
                
            )}

               
                

                










               {/* {
                   noresult === true?(
                      
<div class=" uk-margin-top uk-height-medium  uk-flex uk-flex-center uk-flex-middle">
    <h2>No items in your cart</h2>
    </div>
                   ):(
                    <div className="uk-grid uk-margin-large-top" data-uk-grid>
                 
                    
                    <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                    <h6>Order Summary</h6>
                        {
                            data.map(item => (
                                <div>
                                   
                                     <div class="uk-card uk-card-default uk-card-body uk-card-small uk-margin-small-top uk-margin-small-bottom  " style={{ borderRadius: 10 }}>
                                     
                            <div className="uk-grid" data-uk-grid>
                                <div className=" uk-width-1-5 " >
                                    <div class="uk-text-left">
                                        <img src={myicon} />
                                    </div>
                                </div>

                                <div className=" uk-width-1-3 uk-margin-small-top " >
                                    <div class=" uk-text-left ">
                                        <div>

                                            <p style={{}}>{item.title}</p>
                                        </div>

                                        <div>
                                            <p>{item.duration}</p>
                                            <p>{item.grade}</p>
                                        </div>

                                        <div>
                                            <p>{item.firstName} {item.lastName} {id}</p>
                                        </div>


                                    </div>
                                </div>

                                <div className=" uk-width-1-6 uk-text-center uk-margin-small-top " >
                                    <p>${item.price}
                                    {
                                        discountlastname === item.lastName?(
                                            calculatediscount(item)
                                        
                           
                                        ):(null)
                                    }
                                    
                                    
                                    </p>
                                </div>

                                <div className=" uk-width-1-6  uk-margin-small-top " >

                                    <p onClick={()=> removeitem(
                                       setId(item.id)
                                    ) } class="uk-text-right"> <img src={deleteicon}  width="25px"/></p>
                                </div>
                            </div>
                        </div>
                                 </div>
                                
                               
                            )

                            )
                        }
                    </div>

                    <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 ">
                        <h6>Payment Summary</h6>
                        <div className=" uk-card uk-card-default uk-card-body uk-card-small   uk-margin-large-bottom uk-margin-small-top " style={{ borderRadius: 10, }}>
                            <div className="uk-grid" data-uk-grid>
                                <div className="uk-width-1-2">
                                    <p className="uk-text-left">Sub Total</p>
                                </div>

                                <div className="uk-width-1-2">
                                    <p className="uk-text-right">${subtotal}</p>
                                </div>
                            </div>


                            <div className="uk-grid uk-margin-remove-top" data-uk-grid>
                                <div className="uk-width-1-2 ">
                                    <p className="uk-text-left">Discount</p>
                                </div>

                                <div className="uk-width-1-2">
                                    <strike> <p className="uk-text-right">${discount}</p></strike>
                                </div>

                                <div className="uk-width-1-2 ">
                                    <p className="uk-text-left">
                                    <input className="uk-width-1-1"  type="text" value={coupon} onChange={e => setCoupon(e.target.value)} />
                                    </p>
                                </div>

                                <div className="uk-width-1-2">
                                    {
                                        showcouponbtn == true ?  (
                                            <p className="">
                                    <div onClick={applyCoupon} class=" uk-text-center uk-text-left " style={{fontSize:10, color:'white', backgroundColor:'green'}} type="button" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Apply Coupon</div>
                                    </p>
                                        ): (<></>)
                                    }
                                </div>
                               

                            </div>

                            <hr />
                           
                            <div className="uk-grid" data-uk-grid>
                                <div className="uk-width-1-2">
                                    <p className="uk-text-left">Total</p>
                                </div>

                                <div className="uk-width-1-2">
                                    <p className="uk-text-right">USD ${total}</p>
                                </div>


                            </div>

                            <div className="uk-flex uk-flex-center  uk-margin-small-top">

                                <div onClick={makepayment} class="btn-pay uk-text-center " type="button" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">Pay Now ${total}</div>


                            </div>
                        </div> */}

                        {/* <div class="uk-card-small uk-card-body uk-card-default" style={{borderRadius:'10px'}} >
                            <div className="uk-width-1-1">

                                <div> <h3 className="uk-text-left">Pay</h3> </div>






                                <div>
                                    <form class="uk-grid-small" data-uk-grid>

                                        <div class="uk-width-1-1">
                                            <p className="fieldTitle">Card Number</p>
                                            <input
                                                value={cardNumber}
                                                onChange={(e) => setCardNumber(e.target.value)} class="uk-input customInput" type="text" placeholder="Card number" />
                                        </div>
                                        <div class="uk-width-1-1">
                                            <p className="fieldTitle"> Card Name</p>
                                            <input
                                                value={cardName}
                                                onChange={(e) => setCardName(e.target.value)} class="uk-input customInput" type="text" placeholder="Card name" />
                                        </div>





                                        <div class="uk-width-1-2@s">
                                            <p className="fieldTitle">Exp Date</p>
                                            <input
                                                value={exp}
                                                onChange={(e) => setExp(e.target.value)} class="uk-input customInput" type="text" placeholder="Exp date" />
                                        </div>
                                        <div class="uk-width-1-2@s">
                                            <p className="fieldTitle">Security Number</p>
                                            <input
                                                value={scode}
                                                onChange={(e) => setScode(e.target.value)} class="uk-input customInput" type="text" placeholder="CVV" />
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="uk-flex uk-flex-center uk-margin-small-top">
                                <div onClick={makepayment} style={{ fontSize: '14px' }} class="btn-pay uk-text-center " uk-scrollspy="cls: uk-animation-fade; repeat: true">
                                    Confirm Payment ${total}
                                </div>
                            </div>
                        </div>

 */}









{/* 
                    </div>
                </div>
                   )
               } */}

            </div>






            <div id="modal-example" data-uk-modal>
                <div class="uk-modal-dialog uk-modal-body border-radius" style={{ borderRadius: 5 }}>

                    <div className="uk-width-1-1">

                        <div> <h3 className="uk-text-left">Pay</h3> </div>

                        <div className="dCard uk-margin-medium-bottom">

                            <div className="uk-padding">
                                <p className="uk-margin-large-top uk-text-small light">NUMBER</p>
                                <h3 className="uk-margin-remove-top cardNumber light">1234 5678 890  6789</h3>

                                <div className="uk-grid uk-margin-medium-top" data-uk-grid>
                                    <div className="uk-width-1-2">

                                        <p className=" uk-text-small light">NAME</p>
                                        <p className="uk-text-small light">{cardName}</p>
                                    </div>

                                    <div className="uk-width-1-2">

                                        <p className=" uk-text-small uk-text-right light">EXP DATE</p>
                                        <p className="uk-text-small uk-text-right light">09/24</p>
                                    </div>
                                </div>
                            </div>


                        </div>




                        <div>
                            <form class="uk-grid-small" data-uk-grid>
                                <div class="uk-width-1-1">
                                    <p className="fieldTitle"> Card Name</p>
                                    <input
                                        value={cardName}
                                        onChange={(e) => setCardName(e.target.value)} class="uk-input customInput" type="text" placeholder="Enter the name on your card" />
                                </div>

                                <div class="uk-width-1-1">
                                    <p className="fieldTitle">Card Number</p>
                                    <input
                                        value={cardNumber}
                                        onChange={(e) => setCardNumber(e.target.value)} class="uk-input customInput" type="text" placeholder="Enter the name on your card" />
                                </div>



                                <div class="uk-width-1-2@s">
                                    <p className="fieldTitle">Exp Date</p>
                                    <input
                                        value={exp}
                                        onChange={(e) => setExp(e.target.value)} class="uk-input customInput" type="text" placeholder="Enter the name on your card" />
                                </div>
                                <div class="uk-width-1-2@s">
                                    <p className="fieldTitle">Security Number</p>
                                    <input
                                        value={scode}
                                        onChange={(e) => setScode(e.target.value)} class="uk-input customInput" type="text" placeholder="Enter the name on your card" />
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="uk-flex uk-flex-center uk-margin-small-top">
                        <div class="btn-pay uk-text-center " uk-scrollspy="cls: uk-animation-fade; repeat: true">Confirm Payment ${total}</div>
                    </div>
                </div>
            </div>





        </Layout>
    );
}






//export default mathsTutoring ;