import './App.css';
import HomePage from './pages/HomePage';
import CourseDetailsPage from './pages/CourseDetailsPage';
import DashboardPage from "./pages/DashboardPage"
import CourseCreatePage from "./pages/CourseCreatePage"
import CourseListingPage from "./pages/CourseListingPage";
import BlogCreatePage from './pages/BlogPage'
import BlogListingPage from './pages/BlogPage/blogListPage';
import BlogDetailPage from './pages/BlogPage/blogDetailPage';
import FaqPage from './pages/FaqPage/faqPage';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  Navigation

} from "react-router-dom";
import PrivateRoute from "./features/Authentication/PrivateRoute"
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState } from 'react';
import CourseBookingPage from './pages/CourseBookingPage';
import TeacherPage from './pages/TeacherPage';
import ContactUsPage from './pages/ContactUsPage';
import TermsConditionPage from './pages/TermsConditionPage';
import ComputerCoursePage from './pages/ComputerCoursePage';
import TechCampCoursePage from './pages/TechCampCoursePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ComingSoonPage from './pages/ComingSoonPage';
import AboutUsPage from './pages/AboutUsPage';
import SchoolCreatePage from './pages/SchoolCreatePage';
import SchoolDetailsPage from './pages/SchoolDetailsPage';
import SchoolListingPage from './pages/SchoolListingPage';
import SchoolListingPage2 from './pages/SchoolListingPage2';
import VirtualComputerCourse from './pages/VirtualComputerCourse/VirtualComputerCourse';
import VirtualSmallGroup from './pages/VirtualSmallGroup/VirtualSmallGroup';
import TechCampAtLocation from "./pages/TechCampAtLocation/TechCampAtLocation"
import VirtualTechCamp from './pages/VirtualTechCamp/VirtualTechCamp';
import mathsTutoring from './pages/MathsTutoring/mathsTutoring';
import CodingCoursePage from './pages/CodingCoursePage';
import GameDevCoursePage from './pages/GameDevCoursePage';
import TCCoursePage from './pages/TCCoursePage/tcCoursePage';
import CheckOut from './pages/CheckOutPage/checkout';
import VcheckOut from './pages/CheckOutPage/vcheckout';
import Plan from './pages/PlanPage/Plan';
import axios from 'axios'


function App() {
  useEffect(() => {
      const getUser = () => {
        fetch('http://localhost:27019/auth/login/success', {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
          },
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            throw new Error("authentication has been failed!");
          })
          .then((resObject) => {
            console.log("this is loginData", resObject)
            // let data = {
            //   name:resObject.data._jsonname,
            //   email:resObject.data._json.email,
            //   userId:resObject.data._json.sub,
            //   userType:resObject.meta.userType
            // }
  
            const setJwtToLocal = (data) => {
              localStorage.setItem("cyberteck_login", JSON.stringify(
              {
              name:resObject.data._json.name,
              email:resObject.data._json.email,
              userId:resObject.data._json.sub,
              userType:resObject.meta.userType
              }
                
                ));
            };
  
           setJwtToLocal()
           const getJwtFromLocal = () => {
            const authData = localStorage.getItem("cyberteck_login");
            return JSON.parse(authData);
          };

          return getJwtFromLocal
          
  
          })
          .catch((err) => {
            console.log(err);
          });
      };
      getUser();
     
    }, []);
 
  return (
    <div className="App">
        <Router >
          <ScrollToTop />
          <Switch>
            <Route exact path="/blog/view/:blogId" component={BlogDetailPage} />
            <Route exact path="/faq" component={FaqPage} />
            <Route exact path="/teacher" component={TeacherPage} />
            <Route exact path="/contactus" component={ContactUsPage} />
            <Route exact path="/computer-course" component={ComputerCoursePage} />
            <Route exact path="/coding-courses" component={CodingCoursePage} />
            <Route exact path="/game-dev" component={GameDevCoursePage} />
            <Route exact path="/techcamp-course" component={TechCampCoursePage} />
            <PrivateRoute exact path="/blog/create" authType="TEACHER" component={BlogCreatePage} />
            <PrivateRoute exact path="/blog/edit/:blogId" authType="TEACHER" component={BlogCreatePage} />
            <Route exact path="/blog" component={BlogListingPage} />
            <PrivateRoute exact path="/dashboard" component={DashboardPage} />

            <Route exact path="/termsConditionPage" component={TermsConditionPage} />
            <Route exact path="/privacypolicy" component={PrivacyPolicyPage} />
            <Route exact path="/coming-soon" component={ComingSoonPage} />
            <Route exact path="/about-us" component={AboutUsPage} />

            <PrivateRoute exact path="/courses/create" authType="TEACHER" component={CourseCreatePage} />
            <Route exact path="/courses/:courseId/:title" component={CourseDetailsPage} />
            <PrivateRoute exact path="/courses/:courseId/:title/edit" authType="TEACHER" component={CourseCreatePage} />
            <PrivateRoute exact path="/courses/:courseId/:title/book" component={CourseBookingPage} />
            <Route exact path="/courses" component={CourseListingPage} />

            <PrivateRoute exact path="/schools/create" authType="REPRESENTATIVE" component={SchoolCreatePage} />
            <PrivateRoute exact path="/schools/:schoolId/:title/edit" authType="REPRESENTATIVE" component={SchoolCreatePage} />
            <Route exact path="/schools/:schoolId/:title" component={SchoolDetailsPage} />
            <Route exact path="/schools-list" component={SchoolListingPage} />
            <Route exact path="/schools" component={SchoolListingPage2} />
            <Route exact path="/virtualcourses" component={VirtualComputerCourse} />
            
            <Route exact path="/VirtualSmallGroup" component={VirtualSmallGroup} />
            <Route exact path="/tech-camp-at-school-location" component={TechCampAtLocation} />
            <Route exact path="/tech-camp" component={TCCoursePage} />
            
            <Route exact path="/virtual-tech-camp" component={VirtualTechCamp} />
            <Route  exact path="/maths-tutoring" component={mathsTutoring} />
            <Route  exact path="/checkout" component={CheckOut} />
            <Route  exact path="/vcheckout" component={VcheckOut} />
            <Route  exact path="/plan" component={Plan} />

            <Route exact path="/" component={HomePage} />
          </Switch>
        </Router>
    </div>
  );
}

export default App;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
